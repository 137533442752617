.root {
	display: grid;
	gap: var(--hdsplus-spacing-07);

	&.large {
		@media (--medium-up) {
			gap: var(--hdsplus-spacing-08);
		}
	}

	&.center {
		text-align: center;
	}
}

.content {
	display: grid;

	&:empty {
		display: none;
	}

	@nest .large & {
		gap: var(--hdsplus-spacing-05);

		@media (--large) {
			gap: var(--hdsplus-spacing-07);
		}
	}

	@nest .medium & {
		gap: var(--hdsplus-spacing-05);

		@media (--large) {
			gap: var(--hdsplus-spacing-06);
		}
	}

	@nest .small & {
		gap: var(--hdsplus-spacing-05);
	}
}

.eyebrow-headline {
	display: grid;
	gap: var(--hdsplus-spacing-04);

	&:empty {
		display: none;
	}

	@nest :global(.hashidaysConference) & :global(.hdsplus-typography-label) {
		color: #2E71E5;
	}
}

.ctas {
	@nest .center & {
		justify-content: center;
	}
}